export function escapeUrl ({ input }) {
  return input.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
}

export function formatUrl (template, params) {
  if (!Array.isArray(params)) {
    return template
  }

  let result = template
  for (const param of params) {
    result = result.replace('{' + param.key + '}', param.value)
  }
  return result
}
